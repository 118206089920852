import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
@Injectable({
    providedIn: 'root'
})
export class FacebookPixelService {

    idFacebook = '';

    private loadOk: boolean = false;

    constructor() { }



    load() {
        // this.idFacebook = environment.instagram

        // if (!this.loadOk) {
        //     (function (f: any, b, e, v, n, t, s) {
        //         if (f.fbq) return; n = f.fbq = function () {
        //             n.callMethod ?
        //                 n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        //         }; if (!f._fbq) f._fbq = n;
        //         n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
        //         t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
        //     })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        //     (window as any).fbq.disablePushState = true; //not recommended, but can be done
        //     (window as any).fbq('init', this.idFacebook);
        //     (window as any).fbq('track', 'PageView');
        //     this.loadOk = true;


        // }
        // else {
        //     (window as any).fbq('track', 'PageView');


        // }
    }


    load2(value: any, nomeEmpresa: any) {
        (function (f: any, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        })(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        (window as any).fbq('init', value);
        (window as any).fbq('track', nomeEmpresa);
    }

}