<section class="main-container">

    <h2 class="titulo-home">

        <!-- <nb-icon [icon]="itemsMainBanner.icone" [options]="{animation:{ type: 'pulse' }}" aria-hidden="true"></nb-icon> -->

        <i [class]="'eva eva-' + itemsMainBanner.icone"></i>
        {{itemsMainBanner.titulo}}

    </h2>

    <a *ngIf="itemsMainBanner.path==''">
        <img [src]="itemsMainBanner.baseImagem[0]?.url" alt="" class="img-banner-main">
    </a>

    <a *ngIf="itemsMainBanner.path!=''" href="{{itemsMainBanner.path}}">
        <img [src]="itemsMainBanner.baseImagem[0]?.url" alt="" class="img-banner-main">
    </a>

</section>