<section class="main-container">

    <h2 class="titulo-home">
        <!-- <nb-icon [icon]="itemsGaleria.icone" [options]="{animation:{ type: 'pulse' }}" aria-hidden="true"></nb-icon> -->
        <i [class]="'eva eva-' + itemsGaleria.icone"></i>

        {{itemsGaleria.titulo}}

    </h2>

    <app-galeria [galeriaConfig]="galeriaConfig"></app-galeria>

</section>