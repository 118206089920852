import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { SideBar } from '../../model/side-bar.model';


@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.css']
})
export class AsideComponent implements OnInit, OnChanges {

  @Input('itemsAside') itemsAside: SideBar[];

  itemsAsideDown: SideBar[];

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {

    const currentItemAside: SimpleChange = changes.itemsAside;
    if (currentItemAside.currentValue !== undefined) {
      this.itemsAsideDown = currentItemAside.currentValue;
    }
  }
}
