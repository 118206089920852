import { Dominio } from './../../Shared/model/dominio.model';
import { environment } from './../../../environments/environment.prod';
import { SiteService } from 'src/app/site.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { BaseSite } from 'src/app/Shared/model/base-site.model';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  OnExecuteData,
  OnExecuteErrorData,
  ReCaptchaV3Service,
} from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnChanges, OnDestroy {

  @Input('itemsFooter') itemsFooter: BaseSite;
  mensagem: string;
  enviado = false;
  empresaId: number;
  itemsFooterDown: BaseSite;
  whatsapp = '';
  data;


  formContato: FormGroup;

  public recentToken = "";
  public recentError?: { error: any };
  public readonly executionLog: Array<OnExecuteData | OnExecuteErrorData> = [];

  private allExecutionsSubscription: Subscription;
  private allExecutionErrorsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;



  constructor(
    private siteService: SiteService,
    private recaptchaV3Service: ReCaptchaV3Service,
    protected router: Router,
    private fb: FormBuilder,
  ) {
    this.formContato = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.recentToken = "";
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    // this.siteService.getDominio().subscribe((res: Dominio) => {
    //   environment.tenet = res.codUnicoSchema;
    //   environment.empresaId = res.idUnidade;
    // });
    this.data = new Date().getFullYear();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.recentToken = "";
    const currentItem: SimpleChange = changes.itemsFooter;
    if (currentItem.currentValue !== undefined) {
      if (this.singleExecutionSubscription) {
        this.singleExecutionSubscription.unsubscribe();
      }
      this.itemsFooterDown = currentItem.currentValue;
      if (this.itemsFooterDown.whatsapp !== undefined) {
        this.whatsapp = `https://api.whatsapp.com/send?phone=55${this.itemsFooterDown.whatsapp}`;
      }
    }
  }

  enviarEmail(formContato: NgForm, token) {

    this.enviado = true;

    this.siteService.getMail(formContato.value.email, token).subscribe(res => {

      this.mensagem = 'E-mail enviado!';

      setTimeout(() => {
        this.router.navigateByUrl('/home')
        window.scroll(0, 0);
        this.mensagem = '';
      }, 2000)

      token = "";
      this.recentToken = "";
      setTimeout(() => {
        this.enviado = false;
      }, 5000);

    }, (error) => {
      switch (error.status) {
        case 401:
          this.mensagem = 'ERRO';
          this.enviado = false;

          setTimeout(() => {
            this.enviado = false;
          }, 5000);
          break;
        case 404:
          this.mensagem = 'Insira seu email';
          this.enviado = false;

          setTimeout(() => {
            this.enviado = false;
          }, 5000);
          break;
        case 500:
          this.mensagem = 'ERRO';
          this.enviado = false;
          setTimeout(() => {
            this.enviado = false;
          }, 5000);
          break;
      }
    });

  }

  public executeAction(form): void {
    const action = 'email';
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service
      .execute(action)
      .subscribe(
        (token) => {
          this.recentToken = token;
          console.log(token)
          this.enviarEmail(form, token);
        },
        (error) => {
          this.recentToken = "";
          this.recentError = { error };
        }
      );
  }

  public ngOnDestroy(): void {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.allExecutionErrorsSubscription) {
      this.allExecutionErrorsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  fechar() {
    this.enviado = false;
  }


}




