import { BaseImagem } from 'src/app/Shared/model/base-imagem.model';
import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';


@Component({
  selector: 'app-galleria',
  templateUrl: './galleria.component.html',
  styleUrls: ['./galleria.component.css'],
})
export class GalleriaComponent implements OnInit, OnChanges {

  @Input('images') images: BaseImagem[];

  imagesDown: BaseImagem[];

  displayBasic2: boolean;

  activeIndex;

  displayCustom;

  data: any;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '640px',
      numVisible: 3,
    },

    {
      breakpoint: '450px',
      numVisible: 2,
    },
  ];

  responsiveOptions2: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  constructor() { }


  ngOnChanges(changes: SimpleChanges) {
    const currentItemAside: SimpleChange = changes.images;
    if (currentItemAside.currentValue !== undefined) {
      this.imagesDown = currentItemAside.currentValue;
    }
  }

  ngOnInit() {
  }

  // imageClick(index: number) {
  //   this.activeIndex = index;
  //   this.displayCustom = true;
  // }





  imageClick(index: any) {

    console.log(index, 'test');
    var indice = this.imagesDown.findIndex((item: any) => item.id === index.id)
    console.log(indice, 'test');

    this.displayBasic2 = true;
    this.activeIndex = indice;
    this.displayCustom = true;

  }
}
