<div class="container-pagnoticia">

    <div class="container-thumbnail" *ngIf="itemsNoticias?.baseImagem[0]?.url">
        <a [routerLink]="['/noticia', itemsNoticias.id]">
            <img [src]="itemsNoticias.baseImagem[0]?.url" [alt]="itemsNoticias.nome" />
        </a>
    </div>

    <div class="container-thumbnail" *ngIf="!itemsNoticias.baseImagem[0]?.url">
        <a [routerLink]="['/noticia', itemsNoticias.id]">
            <img src="assets/img/miniatura-noticia-250x160.png" [alt]="itemsNoticias.nome" />
        </a>
    </div>

    <div class="pagnoticia">

        <a [routerLink]="['/noticia', itemsNoticias.id]" style="width: 100%;">
            <h3>
                {{ itemsNoticias.nome }}
            </h3>
        </a>

        <p>
            {{ itemsNoticias.descricao }}
        </p>

        <div>
            <span>
                <i class="fa fa-calendar" aria-hidden="true"></i> {{ itemsNoticias.dataPublic | date:"dd/MM/yyyy"}}
            </span>

            <a [routerLink]="['/noticia', itemsNoticias.id]" alt="Leia mais" class="leia-mais">
                Leia mais <i class="fa fa-angle-right" aria-hidden="true" style="margin-left: 5px;"></i>
            </a>
        </div>

    </div>

</div>