import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appTema]'
})

export class TemaDirective {

  constructor(private el: ElementRef) {

  }

  alterarTema(tema: string) {

    switch (tema) {

      case 'ROXO': {

        this.mudarCor('#6666FF', '#261C55', '#27375b', '#1A133A', 'rgba(0, 0, 0,.1)', '#6666FF', '#261C55', '#261C55', '#6666FF', '#6666FF', '#342673', '#ffffff', '#ffffff')

        break;

      }

      case 'LARANJA': {

        this.mudarCor('#Ff8400', '#0C2B5B', '#27375b', '#091C42', '#Ff8400', '#Ff8400', '#0C2B5B', '#0C2B5B', '#0C2B5B', '#0C2B5B', '#0f3671', '#ffffff', '#ffffff')

        break;
      }

      case 'CIANO': {

        this.mudarCor('#03A9F5', '#0C2239', '#27375b', '#06121E', '#03A9F5', '#03A9F5', '#0C2239', '#0C2239', '#247EEA', 'linear-gradient(0deg, rgba(2,103,191,1) 0%, rgba(2,116,217,1) 100%)', '#16293F', '#ffffff', '#ffffff')

        break;
      }

      case 'AZUL': {

        this.mudarCor('#2174EA', '#101D2D', '#27375b', '#06121E', 'rgba(0, 0, 0, 0.1)', '#03A9F5', '#0C2239', '#0c2461', '#247EEA', '#1644b6', '#16293F', '#ffffff', '#ffffff')

        break;
      }

      case 'VERDE': {

        this.mudarCor('#68B828', '#2C2E2F', '#27375b', '#131414', 'rgba(44, 46, 47, .2)', '#68B828', '#2C2E2F', '#1d1e23', '#5A9E23', '#68B828', '#393b3c', '#ffffff', '#ffffff')

        break;
      }

      case 'VERMELHO': {

        this.mudarCor('#DD3333', '#1C1D1F', '#97241b', '#121314', 'rgba(28, 29, 31,.2)', '#DD3333', '#1C1D1F', '#831616', '#DD3333', '#DD3333', '#292b2e', '#ffffff', '#ffffff')
        break;
      }

      case 'AMARELO': {

        this.mudarCor('#FEA526', '#121215', '#fcd24a', '#000000', '#bd8c0f45', '#121215', '#121215', '#fdeaac', '#4A4A4A', '#e7b526', '#d7aa39', '#d7aa39', '#9b6e08')

        break;
      }

      case 'PADRAO': {

        this.mudarCor('#009926', '#00721d', '#00a529', '#005c17', '#4747471a', '#474747', '#00a529', '#00721d', '#009926', 'linear-gradient( 270deg, var(--cor-escura) 0%, var(--cor-primaria) 70%, var(--cor-escura) 100%)', '#009926', '#ffffff', '#ffffff')

        break;
      }

      case undefined: {

        this.mudarCor('#009926', '#00721d', '#00a529', '#005c17', '#4747471a', '#474747', '#00a529', '#fff4cf', '#009926', 'linear-gradient( 270deg, var(--cor-escura) 0%, var(--cor-primaria) 70%, var(--cor-escura) 100%)', '#16293F', '#ffffff', '#ffffff')

        break;
      }

    }

  }

  mudarCor(primaria, escura, clara, sombra, hover, icone, texto, media, botao, nav, iconesfooter, titulofooter, textoapresentacao) {

    this.el.nativeElement.style.setProperty('--cor-primaria', primaria);
    this.el.nativeElement.style.setProperty('--cor-escura', escura);
    this.el.nativeElement.style.setProperty('--cor-clara', clara);
    this.el.nativeElement.style.setProperty('--cor-sombra', sombra);
    this.el.nativeElement.style.setProperty('--cor-hover', hover);
    this.el.nativeElement.style.setProperty('--cor-icone', icone);
    this.el.nativeElement.style.setProperty('--cor-texto', texto);
    this.el.nativeElement.style.setProperty('--cor-media', media);
    this.el.nativeElement.style.setProperty('--cor-botao', botao);
    this.el.nativeElement.style.setProperty('--cor-nav', nav);
    this.el.nativeElement.style.setProperty('--cor-iconesfooter', iconesfooter);
    this.el.nativeElement.style.setProperty('--cor-titulofooter', titulofooter);
    this.el.nativeElement.style.setProperty('--cor-textoapresentacao', textoapresentacao);

  }

}
