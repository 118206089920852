import { environment } from './../environments/environment.prod';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseSite } from './Shared/model/base-site.model';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  httpOptions;
  idUnidade = environment.empresaId;
  baseSite: BaseSite = new BaseSite();
  constructor(private http: HttpClient) { }


  getDominio() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json'
      }),
    };

    return this.http.get<any>(`${environment.urlSite}/dominio/free/${window.location.hostname}/2`, httpOptions);


  }

  getEmpresa(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json', 'X-TENANT-ID': `${environment.tenet}`
      }),
    };

    return this.http.get(`${environment.urlSite}/unidade/site/${id}`, httpOptions);
  }

  getSite(path: string): Observable<any> {
    this.generateHttpHeader();

    return this.http.get(`${environment.urlSite}/${path}/site/${environment.empresaId}`, this.httpOptions);
  }

  getBaseImagem(idItem: number, hash: string): Observable<any> {
    this.generateHttpHeader();

    return this.http.get(
      `${environment.urlSite}/baseimagem/site/hash/${environment.empresaId}/${idItem}/${hash}`,
      this.httpOptions
    );
  }


  getDataTableCatalogo(idItem: number, hash: string, page, pageSize): Observable<any> {

    this.generateHttpHeader();

    return this.http.get(
      `${environment.urlSite}/baseimagem/site/catalogo-lista/${this.idUnidade}/${idItem}?hashcode=${hash}&page=${page}&pageSize=${pageSize}`,
      this.httpOptions
    );
  }


  getSiteById(path: string, idItem: number): Observable<any> {
    this.generateHttpHeader();

    return this.http.get(
      `${environment.urlSite}/${path}/site/${this.idUnidade}/${idItem}`,
      this.httpOptions
    );
  }

  getNoticiaMaster(): Observable<any> {

    this.generateHttpHeader();

    return this.http.get(
      `${environment.urlSite}/pagina-web/site/buscar-master/${this.idUnidade}`,
      this.httpOptions
    );
  }



  getUltimasNoticia(qnt: number): Observable<any> {
    this.generateHttpHeader();

    return this.http.get(
      `${environment.urlSite}/pagina-web/site/noticia/${environment.empresaId}/${qnt}`,
      this.httpOptions
    );
  }

  getNoticiaPage(page: number, size: number): Observable<any> {
    this.generateHttpHeader();

    return this.http.get(
      `${environment.urlSite}/pagina-web/site/page/${environment.empresaId}/${page}/${size}`,
      this.httpOptions
    );
  }

  getNoticia(idItem: number, tipo: string): Observable<any> {
    this.generateHttpHeader();

    return this.http.get(
      `${environment.urlSite}/pagina-web/site/${environment.empresaId}/${idItem}/${tipo}`,
      this.httpOptions
    );
  }

  getMail(email: string, token): Observable<any> {
    this.generateHttpHeader();

    return this.http.get(
      `${environment.urlSite}/email/free/contato-web/${environment.empresaId}/${email}?token=${token}`,
      this.httpOptions
    );
  }


  contatoEmail(email, token): Observable<any> {
    this.generateHttpHeader();

    return this.http.post(
      `${environment.urlSite}/email/free/contato-web/pagina?token=${token}`, email,
      this.httpOptions
    );
  }

  getAgenda(): Observable<any> {
    this.generateHttpHeader();

    return this.http.get(
      `${environment.urlSite}/agendaschool/free/${environment.empresaId}`,
      this.httpOptions
    );
  }

  protected generateHttpHeader() {
    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json', 'X-TENANT-ID': `${environment.tenet}`
      }),
    };
  }


  // protected generateHttpHeader() {
  //   this.httpOptions = {
  //     headers: new HttpHeaders({
  //       Accept: 'application/json', 'X-TENANT-ID': `${environment.tenet}`,
  //       'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
  //       'Pragma': 'no-cache',
  //       'Expires': '0'
  //     }),
  //   };
  // }

}

