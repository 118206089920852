<div style="display: flex; justify-content: center;">

    <p-galleria [(value)]="imagesDown" [responsiveOptions]="responsiveOptions" [numVisible]="5" [circular]="true"
        [showItemNavigators]="true" [containerStyle]="{ 'max-width': '100%' }" class="galleria-white">


        <ng-template pTemplate="item" let-item>

            <div>
                <img [src]="item.url" class="img-galleria" (click)="imageClick(item)" style="cursor: pointer;" />
            </div>

        </ng-template>

        <ng-template pTemplate="thumbnail" let-item class="galleria-white">
            <div class="p-grid p-nogutter p-justify-center">
                <img [src]="item.url"
                    style="display: block; width: 80px; height: 60px; object-fit: contain; outline: none; border-radius: 5px; outline: unset; margin: 0 auto;" />
            </div>
        </ng-template>


    </p-galleria>

    <p-galleria [(value)]="images" [(visible)]="displayBasic2" [responsiveOptions]="displayCustom" *ngIf="displayBasic2"
        [(activeIndex)]="activeIndex" [numVisible]="7" [circular]="true" [fullScreen]="true" [showItemNavigators]="true"
        [showThumbnails]="false" [baseZIndex]="100000" [containerStyle]="{ 'max-width': '100%' }">

        <ng-template pTemplate="item" let-item>
            <img [src]="item.url" style="max-width: 90vw; max-height: 80%; display: block;" />
        </ng-template>

        <ng-template pTemplate="thumbnail" let-item>
            <div class="p-grid p-nogutter p-justify-center">
                <img [src]="item.url"
                    style="display: block; width: 80px; height: 60px; object-fit: cover; outline: none; border-radius: 5px; outline: unset;" />
            </div>
        </ng-template>

    </p-galleria>

</div>