import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css'],
})

export class GaleriaComponent implements OnChanges, OnInit {

  @Input('galeriaConfig') galeriaConfig = {};

  @Input() responsiveOptions = {};

  galeria: any;

  qntItens = 3;

  responsiveOptions2: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  displayBasic2: boolean;
  activeIndex: number;

  constructor() {
    this.responsiveOptions = [
      {
        breakpoint: '5000px',
        numVisible: 3,
        numScroll: 1,
      },

      {
        breakpoint: '823px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];


  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {


    this.galeria = [];
    const currentItem: SimpleChange = changes.galeriaConfig;
    if (currentItem.currentValue != undefined) {

      if (window.innerWidth < 560) {
        this.qntItens = 1;

      }
      if (window.innerWidth > 560 && window.innerWidth < 823) {
        this.qntItens = 2;

      }
      if (window.innerWidth > 823) {
        this.qntItens = 3;
      }

      this.galeria = currentItem.currentValue.imagemUrl;

    }
  }

  imageClick(index: any) {

    console.log(index, this.galeria, 'galeria')

    var indice = this.galeria.findIndex((item: any) => item.id === index.id)
    console.log(indice, 'test');

    this.activeIndex = indice;
    this.displayBasic2 = true;

  }


}
