import { BaseModule } from './../Shared/base.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnunciosComponent } from './anuncios/anuncios.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';


@NgModule({
  declarations: [NavbarComponent, HeaderComponent, FooterComponent, AnunciosComponent],
  imports: [
    CommonModule,
    // RouterModule.forRoot(routes, { useHash: true }), if this is your app.module

    FormsModule,
    RouterModule,
    BaseModule,
    RecaptchaV3Module,
    ReactiveFormsModule
  ],

  providers: [[{ provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LdBRXMaAAAAAKU1iHr1XgT2h-PjbseDFZo-54uh" }]],
  exports: [NavbarComponent, FooterComponent, HeaderComponent, AnunciosComponent]
})
export class CoreModule { }
