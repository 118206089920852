
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { AsideComponent } from './home-components/aside/aside.component';
import { MainNoticiasComponent } from './home-components/main/main-noticias/main-noticias.component';
import { MainBannerComponent } from './home-components/main/main-banner/main-banner.component';
import { MainGaleriaComponent } from './home-components/main/main-galeria/main-galeria.component';
import { NoticiaComponent } from './home-components/main/noticia/noticia.component';
import { AsideBannerComponent } from './home-components/aside/aside-banner/aside-banner.component';
import { AsideIncorporadoComponent } from './home-components/aside/aside-incorporado/aside-incorporado.component';
import { GaleriaComponent } from './home-components/galeria/galeria.component';
import { GalleriaComponent } from './home-components/galleria/galleria.component';


@NgModule({
  declarations: [
    AsideComponent,
    MainNoticiasComponent,
    MainBannerComponent,
    GaleriaComponent,
    MainGaleriaComponent,
    NoticiaComponent,
    AsideBannerComponent,
    AsideIncorporadoComponent,
    GalleriaComponent,
  ],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    CarouselModule,
    GalleriaModule,

    RouterModule,
  ],

  exports: [
    AsideComponent,
    MainNoticiasComponent,
    MainBannerComponent,
    GaleriaComponent,
    NoticiaComponent,
    GalleriaComponent,
    MainGaleriaComponent
  ],
})
export class BaseModule { }

// GaleriaComponent
