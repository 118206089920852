<section class="secao-aside" style="width: 100%;">

    <h2 class="titulo-side">
        <!-- <nb-icon [icon]="itemsAsideIncorporadoDown.iconeSide" [options]="{animation:{ type: 'pulse' }}" aria-hidden="true" style="font-size: 1em; line-height: 1.2em;"></nb-icon> -->
        <i [class]="'eva eva-' + itemsAsideIncorporadoDown.iconeSide"></i>
        {{itemsAsideIncorporadoDown.tituloSide}}
    </h2>

    <div [innerHTML]="htmlstring" style="width: 100%;" class="centro"></div>

</section>