import { Pagina } from 'src/app/Shared/model/pagina.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.css'],
})
export class NoticiaComponent implements OnInit {
  @Input('noticiaItem') itemsNoticias: Pagina;


  constructor() { }

  ngOnInit(): void {

  }
}
