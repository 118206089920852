import { SiteService } from 'src/app/site.service';
import { Anuncio } from './../../Shared/model/anuncios.model';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Dominio } from 'src/app/Shared/model/dominio.model';

@Component({
  selector: 'app-anuncios',
  templateUrl: './anuncios.component.html',
  styleUrls: ['./anuncios.component.css']
})
export class AnunciosComponent implements OnInit {

  @Input() galeriaConfig = { imagemUrl: [], qntItens: 0 };
  // :AnuncioSlide
  itemsAnuncio;
  // numVisible: number = 3;
  // galeriaConfig

  constructor(public siteService: SiteService) {
  }


  ngOnInit(): void {
    // this.siteService.getDominio().subscribe((res: Dominio) => {
    //   environment.tenet = res.codUnicoSchema;
    //   environment.empresaId = res.idUnidade;
    // this.buscarAnuncioSite();
    // });
  }


}

