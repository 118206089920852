<section class="anuncios" *ngIf="galeriaConfig?.imagemUrl?.length > 0">

    <h1 class="titulo-anuncios"> ANÚNCIOS </h1>

    <div class="slider-anuncios">

        <app-galeria [galeriaConfig]="galeriaConfig"></app-galeria>

    </div>

</section>