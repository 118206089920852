import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { SideBar } from 'src/app/Shared/model/side-bar.model';

@Component({
  selector: 'app-aside-banner',
  templateUrl: './aside-banner.component.html',
  styleUrls: ['./aside-banner.component.css']
})
export class AsideBannerComponent implements OnInit, OnChanges {

  @Input('itemsAsideBanner') itemsAsideBanner: SideBar;

  itemsAsideBannerDown: SideBar;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    const currentItemAside: SimpleChange = changes.itemsAsideBanner;
    if (currentItemAside.currentValue !== undefined) {
      this.itemsAsideBannerDown = currentItemAside.currentValue;
    }
  }
}
