import { Dominio } from './../../../model/dominio.model';
import { environment } from 'src/environments/environment.prod';

import { SiteService } from './../../../../site.service';
import { Pagina } from 'src/app/Shared/model/pagina.model';
import { Main } from './../../../model/main.model';
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-main-noticias',
  templateUrl: './main-noticias.component.html',
  styleUrls: ['./main-noticias.component.css'],
})
export class MainNoticiasComponent implements OnInit {

  @Input('itemsMain') itemsMain: Main;
  itemsNoticias: Pagina[];

  constructor(private siteService: SiteService) { }

  ngOnInit(): void {

    console.log(this.itemsMain, 'itemsMain')
    // fazer req no back em /pagina/idEmpresa/this.itemsNoticias.qntNoticia

    // this.siteService.getDominio().subscribe((res: Dominio) => {

    //   environment.tenet = res.codUnicoSchema;
    //   environment.empresaId = res.idUnidade;

      this.buscarNoticias(this.itemsMain.qntNoticias);
    // });

  }

  buscarNoticias(qnt) {
    this.siteService.getUltimasNoticia(qnt).subscribe((res: Pagina[]) => {
      this.itemsNoticias = res;

    });
  }

}
