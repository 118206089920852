<app-header [itemsHeader]="this.basesite" [unidade]="this.unidade"></app-header>

<app-navbar [qntItensWidth]="qntItensWidth" [itemsNav]="itemsNav" [itemsIcon]="itemsIcon"
    [botaoDropdown]="botaoDropdown"></app-navbar>

<router-outlet *ngIf="load"></router-outlet>

<app-anuncios [galeriaConfig]="galeriaConfig"></app-anuncios>

<app-footer [itemsFooter]="this.basesite"></app-footer>

<!-- <app-chat *ngIf="chat === 'ROLE_CHAT'">
</app-chat> -->