import { SiteService } from './../../site.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Menu } from 'src/app/Shared/model/menu.model';
import { environment } from 'src/environments/environment.prod';
import { Dominio } from 'src/app/Shared/model/dominio.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, AfterViewInit {

  testeMenu = false;
  display = false;
  abrir = true;
  aparecer = true;

  // itemsNav: Menu[];
  // itemsIcon: Menu[];
  // qntItensWidth: any;
  @Input() itemsNav: Menu[] = [];
  @Input() itemsIcon: Menu[] = [];
  @Input() qntItensWidth: any;
  @Input() botaoDropdown: boolean = true;

  @ViewChild('navbar') navBarWidth: ElementRef;
  widthNav;

  // tamanhoArray;

  constructor(public siteService: SiteService, private changeDetectorRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    // this.siteService.getDominio().subscribe((res: Dominio) => {
    //   environment.tenet = res.codUnicoSchema;
    //   environment.empresaId = res.idUnidade;
    // this.buscarNavBar();
    // });

  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();

    this.widthNav = this.navBarWidth.nativeElement.offsetWidth
    // console.log(this.navBarWidth, 'targett2')

  }

  fecharMenu() {
    this.display = !this.display;
  }

  dropResponsivo() {
    this.abrir = !this.abrir;
  }
  botao() {
    this.aparecer = !this.aparecer;
  }






}

