<section class="main-container">
    <h2 class="titulo-home">
        <!-- <nb-icon [icon]="itemsMain.icone" [options]="{animation:{ type: 'pulse' }}" aria-hidden="true"
            style="font-size: 1em; line-height: 1.2em;"></nb-icon> -->

        <i [class]="'eva eva-' + itemsMain.icone"></i>
        {{ itemsMain.titulo }}
    </h2>

    <div *ngFor="let item of itemsNoticias">
        <app-noticia [noticiaItem]="item"></app-noticia>
    </div>

    <a [routerLink]="['/noticias']" class="ver-todas">VER TODAS AS NOTÍCIAS</a>

</section>