import { BaseResourceModel } from './base-resource.model';

export class BaseSite extends BaseResourceModel {
  constructor(
    public id?: number,
    public idUnidade?: number,
    public logo?: string,
    public nomeBannerBase?: string,
    public bannerBase?: string,
    public scpcStatus?: boolean,
    public email?: string,
    public horarioFuncionamento?: string,
    public statusSobre?: boolean,
    public statusBotaoSobre?: boolean,
    public tituloSobre?: string,
    public textoSobre?: string,
    public urlSobre?: string,
    public imagemSobre?: string,
    public nomeImagemSobre?: string,
    public facebook?: string,
    public whatsapp?: string,
    public instagram?: string,
    public youtube?: string,
    public linkedin?: string,
    public telefone?: string,
    public tema?: string,
    public cep?: string,
    public endereco?: string,
    public numero?: string,
    public complemento?: string,
    public bairro?: string,
    public cidade?: string,
    public uf?: string,
    public ibge?: string,
    public gia?: string,
    public ddd?: string,
    public siafi?: string,
    public plano?: string,
    public statusLogo?: boolean,
    public backgroundHeader?: string,
    public bannerUrlExterno?: string,
    public statusBannerBase?: boolean
  ) {
    super();
  }

  static fromJson(jsonData: any): BaseSite {
    return Object.assign(new BaseSite(), jsonData);
  }
}
