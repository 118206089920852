<section class="main-container">

    <p-carousel [value]="galeria" styleClass="custom-carousel" [numVisible]="qntItens" [numScroll]="1" [circular]="true"
        [autoplayInterval]="2000" [responsiveOptions]="responsiveOptions">

        <ng-template let-galeria pTemplate="item">

            <div class="product-item" style="height: 100%; width: 100%;">

                <div class="product-item-content" style="height: 100%;">

                    <a *ngIf="galeria.path" target="_blank" [href]="galeria?.path" class="product-item-content"
                        style="height: 100%;">

                        <img [src]="galeria.url" alt="" class="img-galeria" style="margin: 0 10px 0 10px"
                            alt="Imagem Galeria">

                    </a>

                    <div *ngIf="!galeria.path" class="product-item-content" style="height: 100%; cursor: pointer;">

                        <img [src]="galeria.url" alt="imagem" class="img-galeria" style="margin: 0 10px 0 10px"
                            alt="Imagem Galeria" (click)="imageClick(galeria)">

                    </div>

                </div>

            </div>

        </ng-template>

    </p-carousel>

    <p-galleria [(value)]="galeria" [(visible)]="displayBasic2" [responsiveOptions]="responsiveOptions2"
        [(activeIndex)]="activeIndex" [containerStyle]="{'max-width': '850px'}" [numVisible]="7" [circular]="true"
        [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">

        <ng-template pTemplate="item" let-item>
            <img [src]="item.url" style="width: 100%; display: block;" />
        </ng-template>

        <ng-template pTemplate="thumbnail" let-item>
            <div class="p-grid p-nogutter p-justify-center">
                <img [src]="item.url" style="display: block;" />
            </div>
        </ng-template>

    </p-galleria>

</section>