import { BaseSite } from './../../../model/base-site.model';
import { Component, OnInit, Input, SimpleChanges, SimpleChange, ChangeDetectorRef, ElementRef } from '@angular/core';
import { SideBar } from 'src/app/Shared/model/side-bar.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-aside-incorporado',
  templateUrl: './aside-incorporado.component.html',
  styleUrls: ['./aside-incorporado.component.css']
})
export class AsideIncorporadoComponent implements OnInit {

  @Input('itemsFooter') itemsFooter: BaseSite;
  @Input('itemsAsideIncorporado') itemsAsideIncorporado: SideBar;
  itemsAsideIncorporadoDown: SideBar;
  paginafacebook: any;
  itemsFooterDown: BaseSite;
  url: SafeResourceUrl;

  htmlstring;

  constructor(
    private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef, private elRef: ElementRef
  ) { }


  ngOnInit(): void {


    this.cdRef.detectChanges();


  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItemAside: SimpleChange = changes.itemsAsideIncorporado;
    if (currentItemAside.currentValue !== undefined) {
      this.itemsAsideIncorporadoDown = currentItemAside.currentValue;
      this.htmlstring = this.sanitizer.bypassSecurityTrustHtml(this.itemsAsideIncorporadoDown.codIncorporado);


    }

  }
}
