import { Dominio } from './../../../model/dominio.model';
import { environment } from 'src/environments/environment.prod';
import { Component, Input, OnInit, } from '@angular/core';
import { Main } from 'src/app/Shared/model/main.model';
import { SiteService } from 'src/app/site.service';
import { BaseImagem } from 'src/app/Shared/model/base-imagem.model';

@Component({
  selector: 'app-main-galeria',
  templateUrl: './main-galeria.component.html',
  styleUrls: ['./main-galeria.component.css']
})

export class MainGaleriaComponent implements OnInit {

  @Input('itemsGaleria') itemsGaleria: Main;

  galeria: any;
  galeriaConfig = {};
  itemMainGaleria;
  responsiveOptions;

  constructor(private siteService: SiteService) {

    this.responsiveOptions = [

      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '850px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '620px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit() {
    // this.itemMainGaleria = this.itemsGaleria


    // this.siteService.getDominio().subscribe((res: Dominio) => {
    //   environment.tenet = res.codUnicoSchema;
    //   environment.empresaId = res.idUnidade;
      this.listarGaleria();
    // });

    // Fazer get na tabela de base_imagem passando o ID da Galeria e o Hash

  }

  listarGaleria() {
    this.siteService.getBaseImagem(this.itemsGaleria.id, this.itemsGaleria.hashcode).subscribe((res: BaseImagem[]) => {

      this.galeriaConfig = { imagemUrl: res, qntItens: 3 };

    });
  }
}


