<footer class="footer-portal">
    <div class="footer-container">

        <form [formGroup]="formContato">

            <input type="email" placeholder="Receber novidades" formControlName="email" [readOnly]="enviado" />

            <button type="button" (click)="executeAction(formContato)" [disabled]="formContato.invalid || enviado"
                alt="Receber notícias"><i class="fa fa-envelope-o" aria-hidden="true"></i>
                <!-- Receber novidades -->
            </button>

            <p *ngIf="mensagem" class="toastr alert"
                [ngClass]="{'alert-danger': mensagem == 'ERRO' || mensagem == 'Insira seu email', 'alert-success': mensagem == 'E-mail enviado!'}"
                (click)="fechar()">
                {{mensagem}} <i class="fa fa-times" aria-hidden="true"></i>
            </p>

        </form>

        <div class="informacoes">

            <div class="cards-footer" alt="ENDEREÇO">
                <span>
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                </span>

                <div>
                    <h4>
                        Endereço
                    </h4>
                    <p class="dados" alt="Endereço">
                        {{ itemsFooterDown?.endereco }}, {{ itemsFooterDown?.numero }} <i
                            *ngIf="itemsFooterDown?.complemento"> - </i> {{ itemsFooterDown?.complemento }} <br /> {{
                        itemsFooterDown?.bairro }} - CEP: {{ itemsFooterDown?.cep }} <br /> {{ itemsFooterDown?.cidade
                        }} - {{ itemsFooterDown?.uf
                        }}
                    </p>
                </div>
            </div>

            <div class="cards-footer" alt="CONTATO">
                <span>
                    <i class="fa fa-phone" aria-hidden="true"></i>
                </span>

                <div>
                    <h4>
                        Contato
                    </h4>
                    <p>
                        <a href="tel:{{ itemsFooterDown?.telefone }}" alt="telefone">
                            {{ itemsFooterDown?.telefone }}
                        </a>
                    </p>

                    <p>
                        <a href="mailto:{{ itemsFooterDown?.email }}" alt="email">
                            {{ itemsFooterDown?.email }}
                        </a>
                    </p>

                </div>
            </div>

            <div *ngIf="itemsFooterDown?.horarioFuncionamento" class="cards-footer" alt="ATENDIMENTO">
                <span>
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                </span>

                <div>
                    <h4>
                        Atendimento
                    </h4>
                    <p>
                        {{ itemsFooterDown?.horarioFuncionamento }}
                    </p>
                </div>
            </div>
        </div>

        <div class="midias">
            <h2>Siga-nos:</h2>

            <div>
                <a *ngIf="itemsFooterDown?.facebook" target="_blank" [href]="itemsFooterDown?.facebook"
                    alt="facebook"><i class="fa fa-facebook" aria-hidden="true" alt="facebook"></i></a>
                <a *ngIf="itemsFooterDown?.instagram" target="_blank" [href]="itemsFooterDown?.instagram"
                    alt="instagram"><i class="fa fa-instagram" aria-hidden="true" alt="instagram"></i></a>
                <a *ngIf="itemsFooterDown?.linkedin" target="_blank" [href]="itemsFooterDown?.linkedin"
                    alt="linkedin"><i class="fa fa-linkedin" aria-hidden="true" alt="linkedin"></i></a>
                <a *ngIf="itemsFooterDown?.youtube" target="_blank" [href]="itemsFooterDown?.youtube" alt="youtube"><i
                        class="fa fa-youtube" aria-hidden="true" alt="youtube"></i></a>
                <a *ngIf="itemsFooterDown?.whatsapp" target="_blank" [href]="whatsapp" alt="whatsapp"><i
                        class="fa fa-whatsapp" aria-hidden="true" alt="whatsapp"></i></a>
                <!-- {{itemsFooterDown.whatsapp}} -->
            </div>

        </div>
    </div>

    <div class="copyright">
        © {{data}} <a target="_blank" href="http://conceitocloud.com.br/">Conceito Cloud - v2.3.2</a>
    </div>

</footer>