<section class="secao-aside" style="width: 100%;">

    <h2 class="titulo-side">
        <!-- <nb-icon [icon]="itemsAsideBannerDown.iconeSide" [options]="{animation:{ type: 'pulse' }}" aria-hidden="true">
        </nb-icon> -->

        <i [class]="'eva eva-' + itemsAsideBannerDown.iconeSide"></i>

        {{itemsAsideBannerDown.tituloSide}}
    </h2>

    <a [href]="itemsAsideBannerDown.pathSide" target="_blank">
        <img [src]="itemsAsideBannerDown.baseImagem[0]?.url" class="banner-aside"
            [alt]="itemsAsideBannerDown.baseImagem[0]?.nomeImagem" [title]="itemsAsideBannerDown.tituloSide">
    </a>

</section>