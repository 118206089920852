import { Main } from 'src/app/Shared/model/main.model';
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.css']
})
export class MainBannerComponent implements OnInit {
  @Input('itemsMainBanner') itemsMainBanner: Main;

  public titulo?: string;
  public urlBanner?: string;
  public path?: string;
  public status?: boolean;
  public icone?: string;



  constructor() { }

  ngOnInit(): void {

  }
}
