<header class="header-portal" [ngStyle]="{'background-color': itemsHeader?.backgroundHeader}">

    <!-- <div class="login-bar">
        <a href="http://portal.conceitocloud.com.br" class="" target="_blank">
        <i class="fa fa-lock" aria-hidden="true"></i>&nbsp;Login
        </a>
    </div> -->

    <div class="cabecalho">

        <a routerLink="/home" class="logo-header" alt="Home" *ngIf="itemsHeader?.statusLogo">
            <img [src]="unidade?.imagemUrl" [alt]="unidade?.apelidoNomeFantasia" style="font-size: 0;" />
            <h1 style="font-size: 0; display: none;">{{unidade?.apelidoNomeFantasia}}</h1>
        </a>

        <!-- <a *ngIf="!itemsHeader?.scpcStatus && !itemsHeader?.nomeBannerBase" routerLink="/home" class="btn-header">
            <img src="assets/img/btn-associar.png" alt="link para associar" class="cabecalho-right" />
        </a> -->

        <!-- && !itemsHeader?.scpcStatus -->

        <a [href]="itemsHeader?.bannerUrlExterno" *ngIf="itemsHeader?.statusBannerBase" class="btn-header">
            <img [src]="itemsHeader?.bannerBase" alt="BANNER" />
        </a>

        <!-- target="_blank" -->

        <div *ngIf="itemsHeader?.scpcStatus" class="login-scpc" alt="Login SCPC">

            <div class="logo-scpc">
                <img src="assets/img/logo-boavista.svg" alt="" />
            </div>

            <div class="campos-scpc">
                <h2 class="titulo-scpc" style="color: #fff;">Consultas SCPC</h2>
                <form #loginForm="ngForm" (ngSubmit)="entrarScpc(loginForm)" class="form-scpc">
                    <input type="number" placeholder="Código" name="codigo" class="codigo-senha" ngModel />
                    <input type="password" placeholder="Senha" name="senha" class="codigo-senha" ngModel />
                    <button type="submit" class="botao verde-escuro">Entrar</button>
                </form>
            </div>

        </div>

    </div>

</header>