import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./Pages/component/HomeFolder/home/home.module').then((mod) => mod.HomeModule),

  },

  {
    path: 'informativo',
    loadChildren: () =>
      import('./Pages/component/InformativoFolder/informativo/informativo.module').then(
        (mod) => mod.InformativoModule
      ),
  },
  {
    path: 'noticia/:id',
    loadChildren: () =>
      import('./Pages/component/NoticiaFolder/noticia-pag/noticia-pag.module').then(
        (mod) => mod.NoticiaPagModule
      ),
  },
  {
    path: 'noticias',
    loadChildren: () =>
      import('./Pages/component/NoticiaMasterFolder/noticia-folder/noticia-master.module').then(
        (mod) => mod.NoticiaMasterModule
      ),
  },
  {
    path: 'galeria',
    loadChildren: () =>
      import('./Pages/component/GaleriaFolder/galeria-pag/galeria-pag.module').then(
        (mod) => mod.GaleriaPagModule
      ),
  },
  {
    path: 'catalogo',
    loadChildren: () =>
      import('./Pages/component/CatalogoFolder/catalogo-pag/catalogo.module').then(
        (mod) => mod.CatalogoModule
      ),
  },
  {
    path: 'contato',
    loadChildren: () =>
      import('./Pages/component/ContatoFolder/contato-pag/contato.module').then(
        (mod) => mod.ContatoModule
      ),
  },
  {
    path: 'calendario',
    loadChildren: () =>
      import('./Pages/component/CalendarioFolder/calendario/calendario.module').then(
        (mod) => mod.CalendarioModule
      ),
  },
  {
    path: 'lista-pagina',
    loadChildren: () =>
      import('./Pages/component/ListaPaginaFolder/lista-pagina/lista-pagina.module').then(
        (mod) => mod.ListaDePaginaModule
      ),
  },
  {
    path: 'agenda',
    loadChildren: () =>
      import('./Pages/component/AgendaFolder/agenda/agenda.module').then(
        (mod) => mod.AgendaModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./Pages/component/PaginaIndisponivel/indisponivel/pagina-indisponivel.module').then(
        (mod) => mod.PaginaIndisponivelModule
      ),
  },

  { path: 'noticia', redirectTo: 'noticias', pathMatch: 'full' }




];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
