
import { Empresa } from './../../Shared/model/empresa.model';
import { Component, OnInit, Input } from '@angular/core';
import { BaseSite } from 'src/app/Shared/model/base-site.model';
import { NgForm } from '@angular/forms';
import { SiteService } from 'src/app/site.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() itemsHeader: BaseSite;
  @Input() unidade: Empresa;

  constructor(
    private siteService: SiteService
  ) { }

  ngOnInit(): void {

  }

  entrarScpc(form: NgForm) {

  }

}
