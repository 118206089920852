export const environment = {
  production: true,
  // id unidade \/
  empresaId: 1,
  tenet: '',
  apelidoNomeFantasia: '',
  urlSite: 'https://api.conceitocloud.com.br:8443',
  // urlSite: 'https://api.conceitocloud.com.br:8443',

  // urlSite: 'https://api.conceitopdv.com.br:8443',
  // urlSite: 'http://localhost:8080',
  idModulo: 2,
  pixelKey: ''

  // codUnicoSchema: "",
  // dominio: "",
  // id: 0,

  // idUnidade: 0,

};

