import { TemaDirective } from './tema.directive';

import { environment } from './../environments/environment.prod';
import { SiteService } from './site.service';

import { BaseSite } from './Shared/model/base-site.model';
import { Component, OnInit, Inject } from '@angular/core';

import { Empresa } from './Shared/model/empresa.model';
import { DOCUMENT } from '@angular/common';
import { Dominio } from './Shared/model/dominio.model';
import { Meta } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { Menu } from './Shared/model/menu.model';
import { Anuncio } from './Shared/model/anuncios.model';
import { FacebookPixelService } from './Shared/service/facebook-pixel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {


  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  unidade;
  basesite: BaseSite;
  itemsHeader: BaseSite;
  galeriaConfig = {};
  empresaNome;
  chat = 'SEM CHAT';
  qntItensWidth: any;
  itemsNav: Menu[] = [];
  itemsIcon: Menu[] = [];
  botaoDropdown: boolean = true;
  load = false;

  constructor(
    @Inject(DOCUMENT) private doc,
    public siteService: SiteService,
    public temaDirective: TemaDirective,
    public fbService: FacebookPixelService,
    private metaService: Meta) {
    // this.addTag();
  }

  ngOnInit() {

    // this.changeIcon();

    // this.siteService.getDominio().subscribe((res: Dominio) => {

    //   environment.tenet = res.codUnicoSchema;
    //   environment.empresaId = res.idUnidade;

    //   this.buscarEmpresa();
    // });

    // this.chat

    forkJoin({
      data1: this.siteService.getDominio()
    }).subscribe(response => {

      console.log(response, 'pixel')

      if (response.data1.pixelKey) {
        this.fbService.load2(response.data1.pixelKey, "[SITE] Acessou o site");
      }

      environment.pixelKey = response.data1.pixelKey;
      environment.tenet = response.data1.codUnicoSchema;
      environment.empresaId = response.data1.idUnidade;

      this.load = true;

      this.buscarEmpresa();
      this.buscarNavBar();
      this.buscarAnuncioSite();

    });

  }

  buscarEmpresa() {

    if (window.location.hostname === 'localhost') {
      this.buscarBaseSite();
    } else {
      if (window.location.hostname === 'site.conceitocloud.com.br') {
        this.buscarBaseSite();
      } else {

        // this.siteService.getDominio().subscribe((res: Dominio) => {
        this.siteService.getEmpresa(environment.empresaId).subscribe((res2: Empresa) => {


          // console.log(res2, 'res2')
          this.unidade = res2

          environment.apelidoNomeFantasia = res2.apelidoNomeFantasia

          // environment.tenet = res2.codUnicoSchema;
          this.empresaNome = res2.nome;

          if (res2.faviconUrl) { this.changeIcon(res2.faviconUrl) }

          this.buscarBaseSite();

          for (const item of res2.modulos) {

            if (item.regra === 'ROLE_CHAT') {
              this.chat = item.regra

            }
          }

        });

        // });
      }
    }
  }

  changeIcon(favicon) {
    this.favIcon.href = favicon;
  }

  buscarBaseSite() {
    this.siteService.getSite('basesite-web').subscribe((res) => {
      // console.log(res)
      this.siteService.baseSite = res;
      this.temaDirective.alterarTema(res.tema);

      this.basesite = res;

      // environment.basesite = res

    });
  }

  addTag() {
    this.metaService.addTag({ name: 'description', content: 'Article Description' });
    this.metaService.addTag({ name: 'robots', content: 'index,follow' });
    this.metaService.addTag({ property: 'og:title', content: 'Content Title for social media' });
  }


  buscarNavBar() {

    this.siteService.getSite('menu-web').subscribe((res: Menu[]) => {

      this.qntItensWidth = 0;

      if (window.innerWidth > 1200) {
        this.qntItensWidth = 6;

      } else {
        if (window.innerWidth > 641) {
          this.qntItensWidth = Math.round(window.innerWidth / 200);
        } else {
          if (window.innerWidth < 640) { this.qntItensWidth = 0; }
        }
      }
      this.itemsNav = res.filter((item, i) => i < this.qntItensWidth);
      this.itemsIcon = res.filter((item, i) => i >= this.qntItensWidth);
      this.checkDropdown();
    });

  }

  checkDropdown() {
    if (this.itemsNav !== undefined) {
      if (this.itemsIcon.length >= 1) {
        this.botaoDropdown = true;
      }
      else {
        this.botaoDropdown = false;
      }
    }
  }

  buscarAnuncioSite() {
    this.siteService.getSite('anuncio-web').subscribe((res: Anuncio[]) => {

      let imagens = [];

      for (let i = 0; i < res.length; i++) {
        if (res[i].baseImagem.length !== 0) {
          res[i].baseImagem[0].path = res[i].path;
          imagens.push(res[i].baseImagem[0]);

        }

      }
      this.galeriaConfig = { imagemUrl: imagens, qntItens: 3 };
    });
  }

}

