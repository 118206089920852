<nav class="nav-portal">

    <ul class="nav-ul" #navbar>

        <li class="nav-li home">

            <a routerLink="/home" alt="Home">

                <i class="fa fa-home" aria-hidden="true" style="font-size: 20px;" alt="Home"></i>

            </a>

        </li>

        <li class="nav-li" *ngFor="let item of itemsNav">

            <a target="_blank" href="{{ item.path }}" *ngIf="item.tipo == 'EXTERNO'">
                {{ item.titulo }}
            </a>

            <a [routerLink]="item.path" *ngIf="item.tipo == 'INTERNO'" (click)="display = false">
                {{ item.titulo }}
            </a>

            <a *ngIf="item.tipo == 'SUBMENU'">

                <a>
                    {{ item.titulo }}
                </a>

                <ul *ngIf="item.tipo == 'SUBMENU'" class="nav-submenu">

                    <div *ngFor="let itemsub of item.submenu">

                        <li *ngIf="itemsub.tipo == 'EXTERNO'">
                            <a target="_blank" href="{{ itemsub.path }}" class="">
                                {{ itemsub.titulo }}
                            </a>
                        </li>

                        <li *ngIf="itemsub.tipo == 'INTERNO'">
                            <a [routerLink]="itemsub.path" class=""> {{ itemsub.titulo }}</a>
                        </li>

                    </div>

                </ul>

            </a>

        </li>

    </ul>

    <button class="dropbtn" (click)="fecharMenu()" *ngIf="botaoDropdown" alt="menu">≡</button>

    <nav class="dropdown" [ngClass]="{'block': display == true, 'none': display == false}">

        <ul class="dropdown-content">

            <div *ngFor="let item of itemsIcon">
                <li>
                    <a *ngIf="item.tipo == 'EXTERNO'" target="_blank" href="{{ item.path }}" (click)="fecharMenu()">
                        {{ item.titulo }}
                    </a>
                </li>
                <li>
                    <a *ngIf="item.tipo == 'INTERNO'" [routerLink]="item.path" (click)="fecharMenu();">
                        {{ item.titulo }}
                    </a>
                </li>

                <li *ngIf="item.tipo == 'SUBMENU'" class="nav-li-btn">
                    <a style="display: flex; justify-content: space-between;" (click)="dropResponsivo()">
                        {{ item.titulo }}
                        <i class=" fa fa-angle-right" aria-hidden="true" style="margin-left: 10px;"></i>
                    </a>

                    <ul *ngIf="item.tipo == 'SUBMENU'" class="nav-submenu-btn" [ngClass]="{ 'desativado': !abrir}">

                        <div *ngFor="let itemsub of item.submenu">
                            <li *ngIf="itemsub.tipo == 'EXTERNO'">
                                <a target="_blank" href="{{ itemsub.path }}" class="disabled" (click)="fecharMenu()">
                                    {{ itemsub.titulo }}
                                </a>
                            </li>

                            <li *ngIf="itemsub.tipo == 'INTERNO'">
                                <a [routerLink]="itemsub.path" class="disabled" (click)="fecharMenu();">
                                    {{ itemsub.titulo }}</a>
                            </li>
                        </div>

                    </ul>

                </li>

            </div>

        </ul>

    </nav>

</nav>